var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-6" },
            [
              _c(
                "v-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-secondary border-radius-xl",
                    },
                    [
                      _c("v-img", {
                        staticClass:
                          "position-absolute opacity-4 start-0 top-0 w-100",
                        attrs: {
                          src: require("@/assets/img/shapes/waves-white.svg"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "px-8 py-6" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "position-relative" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "my-auto", attrs: { lg: "3" } },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-h4 text-white opacity-9",
                                    },
                                    [_vm._v(" Since Last Charge ")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal light mt-1 mb-4",
                                  }),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "mb-0 text-h6 font-weight-bold text-white opacity-7",
                                        },
                                        [_vm._v(" Distance ")]
                                      ),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "text-white text-h3 font-weight-bold",
                                        },
                                        [
                                          _vm._v(" 145 "),
                                          _c(
                                            "small",
                                            {
                                              staticClass: "text-sm align-top",
                                            },
                                            [_vm._v("Km")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "ms-lg-6 ms-4" }, [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "mb-0 text-h6 font-weight-bold text-white opacity-7",
                                        },
                                        [_vm._v(" Average Energy ")]
                                      ),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "text-white text-h3 font-weight-bold",
                                        },
                                        [
                                          _vm._v(" 300 "),
                                          _c(
                                            "small",
                                            {
                                              staticClass: "text-sm align-top",
                                            },
                                            [_vm._v("Kw")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center mt-lg-n16",
                                  attrs: { lg: "6" },
                                },
                                [
                                  _c("v-img", {
                                    staticClass:
                                      "w-auto mt-n7 mt-lg-n16 d-none d-md-block",
                                    attrs: {
                                      src: require("@/assets/img/mercedes-eqc.png"),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-h4 font-weight-bold text-white opacity-7 ms-0 ms-md-auto",
                                        },
                                        [_vm._v(" Available Range ")]
                                      ),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "text-h2 font-weight-bolder text-white ms-2 me-auto",
                                        },
                                        [
                                          _vm._v(" 70"),
                                          _c(
                                            "small",
                                            {
                                              staticClass: "text-sm align-top",
                                            },
                                            [_vm._v(" %")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "my-auto", attrs: { lg: "3" } },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-h4 text-white opacity-9",
                                    },
                                    [_vm._v("Nearest Charger")]
                                  ),
                                  _c("hr", {
                                    staticClass: "horizontal light mt-1 mb-4",
                                  }),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", [
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-h6 font-weight-bold mb-0 text-white",
                                        },
                                        [_vm._v(" Miclan, DW ")]
                                      ),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "text-h6 font-weight-bold mb-0 text-white",
                                        },
                                        [_vm._v(" 891 Limarenda road ")]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "ms-16" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              outlined: "",
                                              rounded: "",
                                              color: "#fff",
                                              width: "38px",
                                              height: "38px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "12" } },
                                              [_vm._v("ni ni-map-big")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "3", md: "6", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-secondary border-radius-xl px-4 py-4",
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { sm: "8" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7",
                              },
                              [_vm._v(" Today's Trip ")]
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-h5 text-white font-weight-bolder mb-0",
                              },
                              [_vm._v(" 145 Km ")]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { sm: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow",
                                  attrs: { color: "bg-white", rounded: "" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-typo",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" ni ni-money-coins ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "3", md: "6", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-secondary border-radius-xl px-4 py-4",
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { sm: "8" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7",
                              },
                              [_vm._v(" Battery Health ")]
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-h5 text-white font-weight-bolder mb-0",
                              },
                              [_vm._v("99 %")]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { sm: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow",
                                  attrs: { color: "bg-white", rounded: "" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-typo",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" ni ni-controller ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "3", md: "6", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-secondary border-radius-xl px-4 py-4",
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { sm: "8" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7",
                              },
                              [_vm._v(" Average Speed ")]
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-h5 text-white font-weight-bolder mb-0",
                              },
                              [_vm._v(" 56 Km/h ")]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { sm: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow",
                                  attrs: { color: "bg-white", rounded: "" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-typo",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" ni ni-delivery-fast ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "3", md: "6", cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow bg-gradient-secondary border-radius-xl px-4 py-4",
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { sm: "8" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7",
                              },
                              [_vm._v(" Music Volume ")]
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-h5 text-white font-weight-bolder mb-0",
                              },
                              [_vm._v(" 15/100 ")]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { sm: "4" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "shadow",
                                  attrs: { color: "bg-white", rounded: "" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-typo",
                                      attrs: { size: "20" },
                                    },
                                    [_vm._v(" ni ni-note-03 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "bg-gradient-default border-radius-xl" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "4", md: "6" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { id: "navbar-search-main" },
                                    },
                                    [
                                      _c(
                                        "v-text-field",
                                        {
                                          staticClass:
                                            "font-size-input placeholder-lighter input-alternative input-icon text-white",
                                          attrs: {
                                            "rounded-sm": "",
                                            "hide-details": "",
                                            outlined: "",
                                            "background-color":
                                              "rgba(255,255,255,0)",
                                            color: "rgb(255,255,255)",
                                            lighter: "",
                                            placeholder: "Search anything...",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "prepend-inner" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mt-n1 text-lg",
                                                  attrs: { color: "#fff" },
                                                },
                                                [_vm._v("ni ni-zoom-split-in")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-auto ms-auto",
                                  attrs: { md: "6" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "ms-auto text-lg",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("ni ni-headphones")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Headphones connected"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "ni ni-button-play"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Music is playing"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "ni ni-button-power"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [_c("span", [_vm._v("Start radio")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-lg ms-4",
                                                          attrs: {
                                                            color: "#fff",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("ni ni-watch-time")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [_c("span", [_vm._v("Time tracker")])]
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "text-white text-h4 mb-1 ms-6",
                                        },
                                        [_vm._v("10:45")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("hr", {
                            staticClass: "horizontal light mt-4 mb-4",
                          }),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1",
                                      },
                                      [_vm._v(" 11:13 ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3",
                                      },
                                      [_vm._v(" Estimated arrival time ")]
                                    ),
                                    _c("hr", {
                                      staticClass:
                                        "vertical d-md-block d-none light mt-0 mb-4",
                                    }),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1 ms-auto",
                                      },
                                      [
                                        _vm._v(" 2.4"),
                                        _c(
                                          "small",
                                          { staticClass: "align-top text-sm" },
                                          [_vm._v("Km")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3 me-auto",
                                      },
                                      [_vm._v(" Turn right in 2.4 miles ")]
                                    ),
                                    _c("hr", {
                                      staticClass:
                                        "vertical d-md-block d-none light mt-0",
                                    }),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center position-relative",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "text-h3 font-weight-bolder text-white mb-1 ms-auto",
                                      },
                                      [
                                        _vm._v(" 6.3"),
                                        _c(
                                          "small",
                                          { staticClass: "align-top text-sm" },
                                          [_vm._v("Km")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-white opacity-8 mb-1 ms-3 me-auto",
                                      },
                                      [_vm._v(" Distance to SEO Team Flow ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          { staticStyle: { height: "270px", width: "100%" } },
                          [
                            _vm.showMap
                              ? _c(
                                  "l-map",
                                  {
                                    attrs: {
                                      zoom: _vm.zoom,
                                      center: _vm.center,
                                      options: _vm.mapOptions,
                                    },
                                    on: {
                                      "update:center": _vm.centerUpdate,
                                      "update:zoom": _vm.zoomUpdate,
                                    },
                                  },
                                  [
                                    _c("l-tile-layer", {
                                      attrs: {
                                        url: _vm.url,
                                        attribution: _vm.attribution,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "position-relative" },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { size: "58" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/curved-images/curved10.jpg"),
                                                alt: "Curved",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("img", {
                                          staticClass:
                                            "position-absolute w-60 end-0 bottom-0 me-n4 mb-n2",
                                          attrs: {
                                            src: require("@/assets/img/small-logos/logo-spotify.svg"),
                                            alt: "spotify logo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "px-3" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm font-weight-bold mb-0",
                                        },
                                        [
                                          _vm._v(
                                            " You're Mines Still (feat Drake) "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-xs mb-2 opacity-8",
                                        },
                                        [_vm._v(" Yung Bleu - Hip-Hop ")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { lg: "4", md: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-auto",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "rotate-180",
                                            attrs: { size: "19" },
                                          },
                                          [_vm._v("ni ni-button-play")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-6",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "19" } },
                                          [_vm._v("ni ni-button-pause")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ms-6 me-auto",
                                        attrs: {
                                          icon: "",
                                          outlined: "",
                                          rounded: "",
                                          color: "#fff",
                                          width: "52px",
                                          height: "52px",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "19" } },
                                          [_vm._v("ni ni-button-play")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { lg: "2", md: "6", cols: "8" } },
                                [
                                  _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Volume"),
                                  ]),
                                  _c("v-slider", {
                                    staticClass: "progress-shadow",
                                    attrs: {
                                      color: "bg-primary",
                                      "track-color": "#e9ecef",
                                      "thumb-label": "",
                                      hint: "Im a hint",
                                      max: "100",
                                    },
                                    model: {
                                      value: _vm.slider,
                                      callback: function ($$v) {
                                        _vm.slider = $$v
                                      },
                                      expression: "slider",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ms-auto",
                                  attrs: { lg: "1", md: "6", cols: "4" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "ms-auto mt-4",
                                                      attrs: { color: "#fff" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("ni ni-bullet-list-67")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Hide menu")])]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mt-4 ms-4",
                                                      attrs: { color: "#fff" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("ni ni-chat-round")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Track messages")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }